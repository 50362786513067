import React, { useState, useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from '@emotion/styled';

import AuthLayout from '../components/auth-layout';
import SEO from '../components/seo';
import {
  GRAY_COLOR_RGBA,
  LIGHT_GRAY_COLOR,
  PRIMARY_COLOR,
  GRAY_COLOR,
  YELLOW_COLOR,
  mapColor,
  NotificationsContext,
  Input,
  Label,
  InputContainer,
  InputNoStyle,
  breakpoints as bp,
  Button,
  ButtonIconLeft,
  WHITE_COLOR_RGBA,
} from '@operoute/ui';

import { ReactComponent as BackwardIcon } from '../icons/backward.svg';
import { createAccount } from '../utils/lambda/subscription-request';
import { verifyCodeRequest } from '../utils/lambda/subscription-code';
import { stripeRequest } from '../utils/lambda/stripe-request';
import config from '../utils/config';

import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);


const Header = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.asrow ? 'unset' : '0.4fr 0.6fr')};
  row-gap: ${(props) => (props.asrow ? '0.5rem' : '0.25rem')};
  align-items: flex-end;
  justify-content: space-between;
  box-sizing: border-box;

  ${bp[0]} {
    grid-template-columns: ${(props) =>
    props.asrow ? 'unset' : '0.4fr 0.6fr'};
    row-gap: ${(props) => (props.asrow ? '1rem' : '0.5rem')};
    align-items: baseline;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
  width: 100%;
  min-height: 320px;
  padding: 1rem;
  box-sizing: border-box;

  ${bp[0]} {
    row-gap: 1.5rem;
    width: 460px;
    min-height: 400px;
    padding: 2rem;
  }
`;

const Content = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 100%;
  align-items: center;
  align-content: center;
  row-gap: 1rem;
  padding: 0.5rem;
  border: 1px solid ${GRAY_COLOR_RGBA(0.8)};
  background-color: ${LIGHT_GRAY_COLOR};

  ${bp[0]} {
    grid-template-columns: 90%;
    row-gap: 2rem;
    padding: 1rem;
  }
`;

const ButtonClickable = styled(Button)`
  align-self: flex-end;
  justify-content: center;
`;

const SmallText = styled.span`
  color: ${mapColor()};
  font-size: 0.875rem;
  text-align: ${(props) => props.textalign};
`;

const Link = styled(GatsbyLink)`
  --link-color: ${PRIMARY_COLOR};
`;

const FormLabelInput = styled(Label)`
  column-width: 80px;
`;

const PlanContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  ${bp[0]} {
    column-gap: 0rem;
  }
`;

const PlanTitle = styled.span`
  position: relative;
  font-size: 3rem;
  font-family: var(--secondary-font);
  font-weight: 700;

  &:before {
    content: ${(props) => (props.isprice ? '"$"' : 'unset')};
    position: absolute;
    top: 0.8rem;
    left: -0.8rem;
    font-size: 1rem;
  }
`;

const PlanItem = styled.label`
  display: grid;
  column-gap: 1rem;
  align-content: space-evenly;
  justify-content: center;
  justify-items: center;
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  font-weight: normal;
  background-color: ${LIGHT_GRAY_COLOR};
  border: 1px solid ${GRAY_COLOR};
  box-sizing: border-box;
  cursor: pointer;
`;

const PlanInput = styled(InputNoStyle)`
  display: flex;
  z-index: -1;
  opacity: 0;
  &:checked + ${PlanItem} {
    border-color: ${YELLOW_COLOR};
    border-width: 2px;
  }
`;

const SelectPlanActionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1rem;

  ${bp[0]} {
    grid-template-columns: 0.3fr 0.7fr;
    column-gap: 1.5rem;
  }
`;

const SignupStep = ({ state, handleChange, step, setStep, setLoading }) => {
  const { addNotification } = useContext(NotificationsContext);
  const isValid = () => !state.email || !state.firstName || !state.lastName || !state.organizationName;

  const handleCreateAccount = async () => {
    const data = { ...state };

    setLoading(true);
    const response = await createAccount(data);
    if (response.success) {
      addNotification({
        message: 'Account Created.'
      });
      setStep(2);
      setLoading(false);
    } else {
      addNotification({
        type: 'error',
        message: response.error
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Header>
        <strong>Step {step}</strong>
        <SmallText textalign="end" color="dark_gray">
          <strong>¿Already have an account?</strong>
        </SmallText>
        <SmallText textalign="start" color="dark_gray">
          Create an account
        </SmallText>
        <SmallText textalign="end">
          <Link to="/login">Log in</Link>
        </SmallText>
      </Header>
      <Content>
        <InputContainer>
          <FormLabelInput htmlFor="firstName">
            First Name{' '}
            <abbr title="required" aria-label="required">
              *
            </abbr>
          </FormLabelInput>
          <Input
            onChange={handleChange}
            name="firstName"
            type="text"
            defaultValue={state.firstName}
            placeholder="e.g. John"
            required
          />
        </InputContainer>
        <InputContainer>
          <FormLabelInput htmlFor="lastName">
            Last Name{' '}
            <abbr title="required" aria-label="required">
              *
            </abbr>
          </FormLabelInput>
          <Input
            onChange={handleChange}
            name="lastName"
            type="text"
            defaultValue={state.lastName}
            placeholder="e.g. Smith"
            required
          />
        </InputContainer>
        <InputContainer>
          <FormLabelInput htmlFor="organizationName">
            Organization{' '}
            <abbr title="required" aria-label="required">
              *
            </abbr>
          </FormLabelInput>
          <Input
            onChange={handleChange}
            name="organizationName"
            type="text"
            defaultValue={state.organizationName}
            placeholder="your Organization Name"
            required
          />
        </InputContainer>
        <InputContainer>
          <FormLabelInput htmlFor="email">
            Email{' '}
            <abbr title="required" aria-label="required">
              *
            </abbr>
          </FormLabelInput>
          <Input
            onChange={handleChange}
            name="email"
            type="email"
            defaultValue={state.email}
            placeholder="email@example.com"
            required
          />
        </InputContainer>
      </Content>
      <ButtonClickable
        disabled={isValid()}
        color="white"
        bgcolor="primary"
        onClick={() => handleCreateAccount()}
      >
        Create account
      </ButtonClickable>
    </>
  );
};

const VerificationStep = ({ state, handleChange, step, setStep, setLoading }) => {
  const { addNotification } = useContext(NotificationsContext);
  const isValid = () => !state.verificationCode;

  const handleVerificationCode = async () => {
    setLoading(true);
    const data = { ...state };
    const response = await verifyCodeRequest(data);

    if (response.success) {
      addNotification({
        message: 'Code Verified.',
      });
      setLoading(false);
      setStep(3);
    } else {
      addNotification({
        type: 'error',
        message: 'Invalid Code',
      });
      setLoading(false);
    }
  }

  return (
    <>
      <Header>
        <strong>Step {step}</strong>
        <SmallText textalign="end" color="dark_gray">
          <strong>Confirm Verification Code</strong>
        </SmallText>
      </Header>
      <Content>
        <SmallText textalign="start" color="dark_gray">
          We have sent you the verification code to your email.
        </SmallText>
        <InputContainer>
          <FormLabelInput htmlFor="verificationCode">
            Verification Code{' '}
            <abbr title="required" aria-label="required">
            </abbr>
          </FormLabelInput>
          <Input
            onChange={handleChange}
            name="verificationCode"
            type="text"
            defaultValue={state.verificationCode}
            placeholder="Your verification code"
            required
          />
        </InputContainer>
      </Content>
      <SelectPlanActionButtons>
        <ButtonClickable
          type="button"
          color="dark_gray"
          onClick={() => setStep(1)}
        >
          <ButtonIconLeft>
            <BackwardIcon />
          </ButtonIconLeft>
          Atras
        </ButtonClickable>
        <ButtonClickable
          disabled={isValid()}
          color="white"
          bgcolor="primary"
          onClick={() => handleVerificationCode()}
        >
          Confirm Verification Code
        </ButtonClickable>
      </SelectPlanActionButtons>
    </>
  );
};

const SelectPlanStep = ({ state, handleChange, step, setStep, setLoading }) => {
  const { addNotification } = useContext(NotificationsContext);

  const handleStripeCheckout = async () => {
    setLoading(true);
    const data = { ...state };
    const stripe = await stripePromise;

    const response = await stripeRequest(data);

    if (response.success) {
      addNotification({
        message: `Redirecting to checkout`,
      });
      setLoading(false);
      stripe.redirectToCheckout({
        sessionId: response.data,
      });
    } else {
      addNotification({
        type: 'error',
        message: response.error,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <Header asrow>
        <strong>Step {step}</strong>
        <SmallText textalign="start" color="dark_gray">
          Confirm your plan
        </SmallText>
      </Header>
      <PlanContainer>
        {/* <PlanInput
          id="radio_plan_free"
          type="radio"
          name="plan"
          value="free"
          defaultChecked={state.plan === 'free'}
          onChange={handleChange}
        />
        <PlanItem
          htmlFor="radio_plan_free"
          style={{ width: '100%', height: '100%' }}
        >
          <strong>Trial version</strong>
          <PlanTitle>Free</PlanTitle>
          <span>60 Days</span>
          <strong>Unlimited features</strong>
        </PlanItem> */}
        <PlanInput
          id="radio_plan_subscription"
          type="radio"
          name="plan"
          value="subscription"
          defaultChecked={state.plan === 'subscription'}
          onChange={handleChange}
        />
        <PlanItem
          htmlFor="radio_plan_subscription"
          style={{ width: '100%', height: '100%' }}
        >
          <strong>Operoute Core</strong>
          <PlanTitle isprice>80</PlanTitle>
          <span>Per User per month</span>
          <strong>Free trial for 30 days</strong>
        </PlanItem>
      </PlanContainer>
      <SelectPlanActionButtons>
        <ButtonClickable
          type="button"
          color="dark_gray"
          onClick={() => setStep(2)}
        >
          <ButtonIconLeft>
            <BackwardIcon />
          </ButtonIconLeft>
          Atras
        </ButtonClickable>
        <ButtonClickable type="submit" color="white" bgcolor="primary" onClick={() => handleStripeCheckout()}>
          Proceed to payment
        </ButtonClickable>
      </SelectPlanActionButtons>
    </>
  );
};

const Loading = styled.div`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${WHITE_COLOR_RGBA(0.3)};
  z-index: 1000;
`;

const SignUpPage = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    organizationName: '',
    email: '',
    plan: 'subscription',
    verificationCode: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  return (
    <AuthLayout>
      <SEO title="Subscribe" />
      <Loading isVisible={loading} />
      <Form onSubmit={handleSubmit}>
        {step === 1 && (
          <SignupStep
            state={state}
            handleChange={handleChange}
            step={step}
            setStep={setStep}
            setLoading={setLoading}
          />
        )}
        {step === 2 && (
          <VerificationStep
            state={state}
            handleChange={handleChange}
            step={step}
            setStep={setStep}
            setLoading={setLoading}
          />
        )}
        {step === 3 && (
          <SelectPlanStep
            state={state}
            handleChange={handleChange}
            step={step}
            setStep={setStep}
            setLoading={setLoading}
          />
        )}
      </Form>
    </AuthLayout>
  );
};

export default SignUpPage;
